<div class="project-config panel">
  <h2>{{'PROJECT.Title' | translate}}</h2>
  <div class="row">
    <div class="col-sm-2"><label>{{'SYNCHRONIZATION.ProjectName' | translate}}:</label></div>
    <div class="col-sm-6"><span>{{currentProject?.Name}}</span></div>
  </div>
  <div class="row">
    <div class="col-sm-2"><label>{{'SYNCHRONIZATION.PrimaveraId' | translate}}:</label></div>
    <div class="col-sm-6"><span>{{currentProject?.ProjectId}}</span></div>
  </div>
  <div class="row">
    <div class="col-sm-3">
      <app-return-to-delta-report-btn [projectObjectId]="this.currentProject?.Id"></app-return-to-delta-report-btn>
    </div>
    <div class="project-completed col-sm-7">
      {{'PROJECT.ProjectCompleted' | translate}}:
      <p-inputSwitch [(ngModel)]="isProjectCompleted" (onChange)="statusCompletedDialogHandler($event)"
        [disabled]="projectInfo?.Completed"></p-inputSwitch>
    </div>
  </div>
  <div class="container">
    <div class="row" *ngIf="isAdmin">
      <div class="col-sm-8"></div>
      <div class="form-group col-sm-4">
      </div>
    </div>

    <div class="container-fluid">
      <p-tabView [hidden]="!currentProject" [activeIndex]="activeIndex" (onChange)="handleTabChange($event)">
        <p-tabPanel header="Crew Assignments" leftIcon="pi pi-user-plus"
          tooltip="{{anyCrewAndCoRepsConfigured() ? '': 'Co-Reps and a Primary or Secondary crew required'}}"
          [disabled]="!anyCrewAndCoRepsConfigured() || disablingTabs">
          <app-assignment-grid [assignments]="crewAssignments" [crews]="groupedCrews"
            [isProjectCompleted]="projectInfo?.Completed" [projectID]="this.currentProject?.Id"></app-assignment-grid>
        </p-tabPanel>

        <p-tabPanel header="Co-Reps Assignments" leftIcon="pi pi-user-plus"
          tooltip="{{coRepsConfigured(this.serverConfig) ? '': 'Configuration required'}}"
          [disabled]="!coRepsConfigured(this.serverConfig) || disablingTabs">
          <app-assignment-grid [assignments]="coRepAssignments" [crews]="coRepCrews"
            [isProjectCompleted]="projectInfo?.Completed" [projectID]="this.currentProject?.Id">
          </app-assignment-grid>
        </p-tabPanel>

        <p-tabPanel header="Crews" leftIcon="pi pi-users"
          tooltip="{{coRepsConfigured(this.serverConfig) ? '': 'Configuration required'}}"
          [disabled]="!coRepsConfigured(this.serverConfig) || disablingTabs">
          <p-table #tt [value]="crews" [globalFilterFields]="['HierarchyPath','Description','typeName']">
            <ng-template pTemplate="caption">
              <div class="row">
                <div class="col-sm-6">
                  <input type="text" pInputText placeholder="{{'SHARED.Filter' | translate}}"
                    (input)="tt.filterGlobal($event.target.value, 'contains')" class="form-control">
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th scope="col" pSortableColumn="Name">{{'PROJECT.Name' | translate}} <p-sortIcon field="Name"></p-sortIcon>
                </th>
                <th scope="col" pSortableColumn="Description">{{'PROJECT.Description' | translate}} <p-sortIcon field="Description"></p-sortIcon>
                </th>
                <th scope="col" pSortableColumn="Type">{{'PROJECT.Type' | translate}} <p-sortIcon field="Type"></p-sortIcon>
                </th>
                <th scope="col" pSortableColumn="IsDeleted" style="width: 110;">{{'PROJECT.Disabled' | translate}}<p-sortIcon field="IsDeleted"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-crew>
              <tr>
                <td>{{crew.HierarchyPath}}</td>
                <td>{{crew.Description}}</td>
                <td>{{crew.typeName}}</td>
                <td>
                  <p-inputSwitch [(ngModel)]="crew.IsDeleted" (click)="updateCrew(crew)"
                    [disabled]="projectInfo?.Completed"></p-inputSwitch>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>

        <p-tabPanel header="Activity Code Types" leftIcon="pi pi-cog" [disabled]="disablingTabs">
          <h4>{{'PROJECT.PrimaveraCodeTypes' | translate}} {{currentProject ? currentProject.Name: ''}}</h4>
          <div class="row">
            <div class="col-sm-4">
              <label>{{'PROJECT.PrimaryCrewCode' | translate}}:</label>
              <p-dropdown [(ngModel)]="config['Crew-1']" [options]="groupedActivityCode" [group]="true"
                [style]="{'width':'100%'}" [disabled]="projectInfo?.Completed" [placeholder]="'SHARED.SelectOption' | translate"
                [filter]="true" showClear="true">
                <ng-template let-it pTemplate="item">
                  <span class="list-sub-item">{{it.label}}</span><span *ngIf="it.value && it.value.Description">:
                    {{it.value.Name}}</span>
                </ng-template>
                <ng-template let-it pTemplate="selectedItem">
                  <span>{{it.label}}</span>
                  <span *ngIf="it.value && it.value.Description">: {{it.value.Name}}</span>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-sm-4">
              <label>{{'PROJECT.SupportingCrewCode' | translate}}:</label>
              <p-dropdown [(ngModel)]="config['Crew-2']" [options]="groupedActivityCode" [group]="true"
                [style]="{'width':'100%'}" [disabled]="projectInfo?.Completed" [placeholder]="'SHARED.SelectOption' | translate"
                [filter]="true" showClear="true">
                <ng-template let-it pTemplate="item">
                  <span class="list-sub-item">{{it.label}}</span><span *ngIf="it.value && it.value.Description">:
                    {{it.value.Name}}</span>
                </ng-template>
                <ng-template let-it pTemplate="selectedItem">
                  <span>{{it.label}}</span>
                  <span *ngIf="it.value && it.value.Description">: {{it.value.Name}}</span>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-sm-4">
              <label>{{'PROJECT.CompanyRepCode' | translate}}:</label>
              <p-dropdown [(ngModel)]="config['Co-Reps']" [options]="groupedActivityCode" [group]="true"
                [style]="{'width':'100%'}" [disabled]="projectInfo?.Completed" [placeholder]="'SHARED.SelectOption' | translate"
                [filter]="true" showClear="true">
                <ng-template let-it pTemplate="item">
                  <span class="list-sub-item">{{it.label}}</span><span *ngIf="it.value && it.value.Description">:
                    {{it.value.Name}}</span>
                </ng-template>
                <ng-template let-it pTemplate="selectedItem">
                  <span>{{it.label}}</span>
                  <span *ngIf="it.value && it.value.Description">: {{it.value.Name}}</span>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="row" style="margin-bottom: 1em">
            <div class="col-sm-4">
              <label>{{'PROJECT.EWO' | translate}}:</label>
              <p-dropdown [(ngModel)]="config['PA Job Number']" [options]="groupedactivityCodeTypes" [group]="true"
                [style]="{'width':'100%'}" [disabled]="projectInfo?.Completed" [placeholder]="'SHARED.SelectOption' | translate"
                [filter]="true" showClear="true">
                <ng-template let-it pTemplate="item">
                  <span class="list-sub-item">{{it.label}}</span><span *ngIf="it.value && it.value.Description">:
                    {{it.value.Name}}</span>
                </ng-template>
                <ng-template let-it pTemplate="selectedItem">
                  <span>{{it.label}}</span>
                  <span *ngIf="it.value && it.value.Description">: {{it.value.Name}}</span>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-sm-8">
              <div class="btn-container labeled right">
                <p-button label="{{'SHARED.Save' | translate}}" icon="pi pi-check" [loading]="loading" (onClick)="confirmSave()"
                  [disabled]="!isSaveCodeTypesEnabled() || projectInfo?.Completed"></p-button>
              </div>
            </div>
          </div>
        </p-tabPanel>

        <p-tabPanel header="Shifts" leftIcon="pi pi-calendar-plus" [disabled]="disablingTabs">
          <ng-template pTemplate="content">
            <app-shift-grid [project]="currentProject" [shifts]="projectShifts"
              [offsetHour]="projectPSREndtimeOffsetHour" [offsetMinute]="projectPSREndtimeOffsetMinute"
              [isProjectCompleted]="projectInfo?.Completed" [projectLocation]="projectLocation"
              [projectTimezone]="projectTimezone"></app-shift-grid>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="Basic Project Information" leftIcon="pi pi-calendar-plus"
          tooltip="{{!this.projectTimezone ? 'Could not retrieve the project timezone': ''}}">
          <h4>{{'PROJECT.ProjectInformation' | translate}}</h4>
          <form [formGroup]="projectInfoForm">
            <div class="grid">
              <div class="col-4">
                <label>{{'PROJECT.ProjectType' | translate}}:</label>
                <p-dropdown [required]="true" [options]="projectTypes" [style]="{'width':'100%'}"
                  [placeholder]="'SHARED.SelectOption' | translate" [disabled]="projectInfo?.Completed"
                  formControlName="projectTypes" (onChange)="setProject()">
                </p-dropdown>
              </div>
              <div class="col-4">
                <label>{{'PROJECT.PrimarySchedulerName' | translate}}:</label>
                <p-dropdown filterBy="fullName,Email" [placeholder]="'SHARED.SelectUser' | translate" showClear="true"
                  [options]="userList" [disabled]="projectInfo?.Completed" [filter]="true" (onChange)="addUser($event)"
                  optionLabel="Id" itemSize="30" [style]="{'width':'100%'}"
                  formControlName="schedulerName">
                  <ng-template let-it pTemplate="selectedItem">
                    {{it.FirstName}} {{it.LastName}}
                  </ng-template>
                  <ng-template let-it pTemplate="item">
                    <label>{{it.FirstName}} {{it.LastName}}</label><br>
                    <small>{{it.Email}}</small>
                  </ng-template>
                </p-dropdown>
              </div>
              <div class="col-4">
                <label>{{'PROJECT.ContactPreference' | translate}}:</label>
                <p-dropdown [options]="contactInformation" [style]="{'width':'100%'}"
                [placeholder]="'SHARED.SelectOption' | translate" [disabled]="projectInfo?.Completed"
                formControlName="contactInformation" showClear="true">
                </p-dropdown>
              </div>
            </div>
            <div class="grid">
              <div class="col-4">
                <label>{{'PROJECT.ApproximateUpdateStart' | translate}}:</label>
                <p-calendar [required]="true" [style]="{'width':'100%'}" [keepInvalid]="true"
                [readonlyInput]="true" [disabled]="projectInfo?.Completed" [inputStyle]="{'width':'100%'}"
                formControlName="startDate">
                </p-calendar>
                <label
                  *ngIf=" (projectInfo?.StartDate && projectInfo?.FinishDate) && (projectInfo?.StartDate > projectInfo?.FinishDate)"
                  class="invalid">{{'PROJECT.CantBeHigher' | translate}}</label>
              </div>
              <div class="col-4">
                <label>{{'PROJECT.FinishDate' | translate}}:</label>
                <p-calendar [required]="true" [style]="{'width':'100%'}" formControlName="finishDate"
                [readonlyInput]="true" [disabled]="projectInfo?.Completed" [inputStyle]="{'width':'100%'}">
                </p-calendar>
              </div>
              <div class="col-4">
                <label>{{'PROJECT.UpdatePeriod' | translate}}:</label>
                <p-dropdown [options]="updatePeriod" [style]="{'width':'100%'}" [placeholder]="'SHARED.SelectOption' | translate"
                  [disabled]="projectInfo?.Completed" showClear="true"
                  formControlName="updatePeriod">
                </p-dropdown>
              </div>
            </div>
            <div class="row" style="margin-bottom: 1em">
              <div class="col-sm-12">
                <div class="btn-container labeled right">
                  <p-button label="{{'SHARED.Save' | translate}}" icon="pi pi-check"  (onClick)="saveProjectInfo()"
                    [disabled]="!isSaveProjectInfoEnabled()">
                  </p-button>
                </div>
              </div>
            </div>
          </form>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>

<p-dialog header="Time Zone cannot be determined" [(visible)]="showNoTimeZoneModal" [modal]="true" [closable]="false">
  <p>
    {{ this.currentProject?.ProjectId }} <span [innerHTML]="'PROJECT.ZoneNotDetermined' | translate"></span>
  </p>
  <p-footer>
    <button type="button" pButton icon="pi pi-home" (click)="this.navigateToHome()" label="{{'SHARED.Home' | translate}}"></button>
  </p-footer>
</p-dialog>

<p-dialog header="Save changes" [(visible)]="displaySaveDialog" [responsive]="true" showEffect="fade" [modal]="true"
  [closable]="false">
  <div class="ui-grid ui-grid-responsive ui-fluid">
    <div class="ui-grid-row">
      <p [innerHTML]="'PROJECT.SaveChanges' | translate"></p>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" pButton icon="pi pi-times" (click)="setToCompleted(false)" label="{{'SHARED.No' | translate}}"></button>
      <button type="button" pButton icon="pi pi-check" (click)="setToCompleted(true)" label="{{'SHARED.YesSave' | translate}}"></button>
    </div>
  </p-footer>
</p-dialog>

<p-dialog header="Warning!!" [(visible)]="confirmChangeDialogVisible" [responsive]="true" showEffect="fade" [modal]="true"
  [closable]="false">
  <div class="ui-grid ui-grid-responsive ui-fluid">
    <div class="ui-grid-row">
        <p [innerHTML]="'PROJECT.Warning' | translate"></p>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" pButton icon="pi pi-times" (click)="saveConfig(false)" label="{{'SHARED.No' | translate}}"></button>
      <button type="button" pButton icon="pi pi-check" (click)="saveConfig(true)" label="{{'SHARED.Yes' | translate}}"></button>
    </div>
  </p-footer>
</p-dialog>

