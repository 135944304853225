
  <div class="grid" >
    <div class="col-2">
      {{'COMPONENTS.Activity' | translate}}
    </div>
    <div class="col-4">
      <p-autoComplete
        [(ngModel)]="selectedActivity"
        [suggestions]="filteredActivities"
        (completeMethod)="filterActivities($event)"
        field="displayName"
        emptyMessage="No results"
        [forceSelection]="true"
        (onClear)="clearSelectedActivity()"
        [inputStyle]="{'width':'30rem'}">
        <ng-template let-activity pTemplate="item">
          <label>{{activity.ActivityId}}</label>
          <br>
          <small>{{activity.Name}}</small>
        </ng-template>
      </p-autoComplete>
    </div>
    <div class="col-1">
      {{'COMPONENTS.User' | translate}}
    </div>
    <div class="col-4">
      <p-dropdown
        showClear="true"
        [options]="users"
        [(ngModel)]="selectedUser"
        optionLabel="Email"
        itemSize="30"
        [filter]="true"
        [style]="{'width':'30rem'}"
        [placeholder]="'COMPONENTS.SelectUser' | translate"
        filterBy="Email">
      </p-dropdown>
    </div>
  </div>
  <div class="grid">
    <div class="col-2">
      {{'COMPONENTS.StartDate' | translate}}:
    </div>
    <div class="col-4">
      <p-calendar [(ngModel)]="selectedStartDate" [showTime]="true" [showIcon]="true" [style]="{'width':'30rem'}"></p-calendar>
    </div>
    <div class="col-1">
      {{'COMPONENTS.To' | translate}}:
    </div>
    <div class="col-4">
      <p-calendar [(ngModel)]="selectedEndDate" [showTime]="true" [showIcon]="true" [style]="{'width':'30rem'}"></p-calendar>
    </div>
  </div>
  <div class="row">

  </div>

<p-button icon="pi pi-search"label="{{'COMPONENTS.Search' | translate}}" (click)="ExecuteSearch()"></p-button>
